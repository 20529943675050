// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-billfixers-tsx": () => import("./../../../src/pages/billfixers.tsx" /* webpackChunkName: "component---src-pages-billfixers-tsx" */),
  "component---src-pages-cohub-tsx": () => import("./../../../src/pages/cohub.tsx" /* webpackChunkName: "component---src-pages-cohub-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ndw-tsx": () => import("./../../../src/pages/ndw.tsx" /* webpackChunkName: "component---src-pages-ndw-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-preferr-tsx": () => import("./../../../src/pages/preferr.tsx" /* webpackChunkName: "component---src-pages-preferr-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

